
@font-face {
    font-family: 'liebling';
    src: url('LieblingProHeavy.woff2') format('woff2'),
         url('LieblingProHeavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'liebling';
    src: url('LieblingProBold.woff2') format('woff2'),
         url('LieblingProBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'liebling';
    src: url('LieblingProMedium.woff2') format('woff2'),
         url('LieblingProMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'liebling';
    src: url('LieblingProRegular.woff2') format('woff2'),
         url('LieblingProRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'liebling';
    src: url('LieblingProLight.woff2') format('woff2'),
         url('LieblingProLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'liebling';
    src: url('LieblingProThin.woff2') format('woff2'),
         url('LieblingProThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
